import { Box, Button, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Image, Input, InputGroup, InputRightElement, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import Logo from '../../assets/images/logo.png';
import LogoWhite from '../../assets/images/logo-white.png';
import { Link, useNavigate } from 'react-router-dom';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { signup } from '../../redux/user/userActions';
import { Helmet } from 'react-helmet';

const Signup = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isSubmitting = useSelector((state) => state.user?.is_user_registration_loading);
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const {
        handleSubmit,
        register,
        formState: { errors },
        watch
    } = useForm();

    const password = useRef({});
    password.current = watch("password", "");

    const showPassword = () => {
        setShowPass(!showPass);
    }

    const showConfirmPassword = () => {
        setShowConfirmPass(!showConfirmPass);
    }

    const onSubmit = (values) => {
        dispatch(signup(values, afterSuccess, afterError));
    }

    const afterSuccess = () => {
        navigate('/authentication');
    }

    const afterError = (msg) => {
        setEmailError(msg)
    }

    return (
        <Box bgColor={'#2BB673'} w={'100%'} h={'100vh'}>
            <Helmet>
                <title>Sign Up | Remitless</title>
            </Helmet>
            <Box>
                <SimpleGrid columns={2} h={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'space-around'}>
                    <Box>
                        <Image src={LogoWhite} />
                    </Box>
                    <Box pos={'relative'} zIndex={'9'}>
                        <Box
                            sx={{
                                _before: {
                                    content: '""',
                                    pos: 'absolute',
                                    top: '0',
                                    left: '-35px',
                                    h: '90%',
                                    w: '100%',
                                    bgColor: '#ffffff60',
                                    borderRadius: '30px 0px 0px 30px',
                                    m: '30px 0px 0px 0px',
                                    zIndex: -1
                                }
                            }}
                        ></Box>
                        <Box
                            sx={{
                                _before: {
                                    content: '""',
                                    pos: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    left: '-70px',
                                    h: '80%',
                                    w: '100%',
                                    bgColor: '#ffffff40',
                                    borderRadius: '30px 0px 0px 30px',
                                    m: '60px 0px 0px 0px',
                                    zIndex: -1
                                }
                            }}
                        ></Box>
                        <Box
                            sx={{
                                _before: {
                                    content: '""',
                                    pos: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    left: '-105px',
                                    h: '70%',
                                    w: '100%',
                                    bgColor: '#ffffff20',
                                    borderRadius: '30px 0px 0px 30px',
                                    m: '90px 0px 0px 0px',
                                    zIndex: -1
                                }
                            }}
                        ></Box>
                        <Box bgColor={'#fff'} w={'600px'} h={'650px'} borderRadius={'30px'}>
                            <Flex p={'50px 75px 50px 75px'} flexDirection={'column'} alignItems={'center'}>
                                <Image src={Logo} w={'250px'} />
                                <Text color={'#000'} fontSize={'20px'} fontFamily={'poppins'} mt={'20px'}>Sign Up</Text>
                                <Box w={'100%'} mt={'20px'}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <SimpleGrid columns={2} spacing='10px'>
                                            <Box>
                                                <FormControl isInvalid={errors?.first_name?.message}>
                                                    <FormLabel htmlFor="first_name" color={'#000'}>First Name*</FormLabel>
                                                    <Input
                                                        id="first_name"
                                                        bgColor={'#F8F7FC'}
                                                        borderColor={'#F8F7FC'}
                                                        _hover={{ borderColor: '#F8F7FC' }}
                                                        color={'#9A999C'}
                                                        {...register('first_name', {
                                                            required: 'This is required *',
                                                            minLength: { value: 2, message: 'Minimum length should be 2' },
                                                        })}
                                                    />
                                                    {errors?.first_name?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.first_name?.message}</Text>}
                                                </FormControl>
                                            </Box>
                                            <Box>
                                                <FormControl isInvalid={errors?.last_name?.message}>
                                                    <FormLabel htmlFor="last_name" color={'#000'}>Last Name*</FormLabel>
                                                    <Input
                                                        id="last_name"
                                                        bgColor={'#F8F7FC'}
                                                        borderColor={'#F8F7FC'}
                                                        _hover={{ borderColor: '#F8F7FC' }}
                                                        color={'#9A999C'}
                                                        {...register('last_name', {
                                                            required: 'This is required *',
                                                            minLength: { value: 2, message: 'Minimum length should be 2' },
                                                        })}
                                                    />
                                                    {errors?.last_name?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.last_name?.message}</Text>}
                                                </FormControl>
                                            </Box>
                                            <Box>
                                                <FormControl isInvalid={errors?.email?.message || emailError}>
                                                    <FormLabel htmlFor="email" color={'#000'}>Email address*</FormLabel>
                                                    <Input
                                                        id="email"
                                                        bgColor={'#F8F7FC'}
                                                        borderColor={'#F8F7FC'}
                                                        _hover={{ borderColor: '#F8F7FC' }}
                                                        color={'#9A999C'}
                                                        {...register("email", {
                                                            required: "This is required *",
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: "Invalid email format",
                                                            },
                                                        })}
                                                    />
                                                    {errors?.email?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.email?.message}</Text>}
                                                    {emailError && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{emailError}</Text>}
                                                </FormControl>
                                            </Box>
                                            <Box>
                                                <FormControl isInvalid={errors?.phone?.message}>
                                                    <FormLabel htmlFor="phone" color={'#000'}>Phone*</FormLabel>
                                                    <Input
                                                        id="phone"
                                                        type="tel"
                                                        bgColor={'#F8F7FC'}
                                                        borderColor={'#F8F7FC'}
                                                        _hover={{ borderColor: '#F8F7FC' }}
                                                        color={'#9A999C'}
                                                        {...register('phone', {
                                                            required: 'This is required *',
                                                            minLength: { value: 10, message: 'Minimum length should be 10' },
                                                            maxLength: { value: 12, message: 'Maximum length should be 12' }
                                                        })}
                                                    />
                                                    {errors?.phone?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.phone?.message}</Text>}
                                                </FormControl>
                                            </Box>
                                            <Box>
                                                <FormControl isInvalid={errors?.password?.message}>
                                                    <FormLabel htmlFor="password" color={'#000'}>Password*</FormLabel>
                                                    <InputGroup>
                                                        <Input
                                                            id="password"
                                                            type={showPass ? 'text' : 'password'}
                                                            bgColor={'#F8F7FC'}
                                                            borderColor={'#F8F7FC'}
                                                            _hover={{ borderColor: '#F8F7FC' }}
                                                            color={'#9A999C'}
                                                            {...register('password', {
                                                                required: 'This is required *',
                                                                minLength: { value: 8, message: "Password must have at least 8 characters" }
                                                            })}
                                                        />
                                                        <InputRightElement>
                                                            {showPass ?
                                                                <ViewOffIcon onClick={showPassword} cursor={'pointer'} color={'#9A999C'} /> :
                                                                <ViewIcon onClick={showPassword} cursor={'pointer'} color={'#9A999C'} />
                                                            }
                                                        </InputRightElement>
                                                    </InputGroup>
                                                    {errors?.password?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.password?.message}</Text>}
                                                </FormControl>
                                            </Box>
                                            <Box>
                                                <FormControl isInvalid={errors?.confirm_password?.message}>
                                                    <FormLabel htmlFor="confirm_password" color={'#000'}>Confirm Password*</FormLabel>
                                                    <InputGroup>
                                                        <Input
                                                            id="confirm_password"
                                                            type={showConfirmPass ? 'text' : 'password'}
                                                            bgColor={'#F8F7FC'}
                                                            borderColor={'#F8F7FC'}
                                                            _hover={{ borderColor: '#F8F7FC' }}
                                                            color={'#9A999C'}
                                                            {...register('confirm_password', {
                                                                validate: value =>
                                                                    value === password.current || "Both passwords must be same"
                                                            })}
                                                        />
                                                        <InputRightElement>
                                                            {showConfirmPass ?
                                                                <ViewOffIcon onClick={showConfirmPassword} cursor={'pointer'} color={'#9A999C'} /> :
                                                                <ViewIcon onClick={showConfirmPassword} cursor={'pointer'} color={'#9A999C'} />
                                                            }
                                                        </InputRightElement>
                                                    </InputGroup>
                                                    {errors?.confirm_password?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.confirm_password?.message}</Text>}
                                                </FormControl>
                                            </Box>
                                        </SimpleGrid>
                                        <FormControl mt={'10px'} isInvalid={errors?.accept_terms?.message}>
                                            <Checkbox
                                                id="accept_terms"
                                                color={'#B1ADC0'}
                                                {...register('accept_terms', {
                                                    required: 'This is required *'
                                                })}
                                            >
                                                I accept <Link style={{ textDecoration: 'underline', color: '#2BB673' }}>Terms & Conditions</Link> and <Link style={{ textDecoration: 'underline', color: '#2BB673' }}>Privacy Policy</Link>
                                            </Checkbox>
                                            {errors?.accept_terms?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.accept_terms?.message}</Text>}
                                        </FormControl>
                                        <Button type='submit' isLoading={isSubmitting} mt={'20px'} bgColor={'#0A2B1B'} _hover={{ bgColor: '#2BB673' }} w={'100%'} color={'#fff'} fontWeight={300} textTransform={'uppercase'} p={'20px'} borderRadius={'6px'}>Sign Up</Button>
                                    </form>
                                    <HStack gap={'10px'} fontSize={'16px'} mt={'20px'}>
                                        <Text color={'#000'} fontWeight={'600'}>Already a member ?</Text>
                                        <Link to={'/login'} style={{ textDecoration: 'underline', color: '#2BB673' }}>Log in</Link>
                                    </HStack>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                </SimpleGrid>
            </Box>
        </Box>
    );
}

export default Signup;
