import { Box, Button, Checkbox, Flex, FormControl, FormLabel, HStack, Image, Input, InputGroup, InputRightElement, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import Logo from '../../assets/images/logo.png';
import LogoWhite from '../../assets/images/logo-white.png';
import { Link, useNavigate } from 'react-router-dom';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/user/userActions';
import { Helmet } from 'react-helmet';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isSubmitting = useSelector((state) => state?.user?.is_user_login_loading);
    const [show, setShow] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passError, setPassError] = useState(false);

    const {
        handleSubmit,
        register,
        formState: { errors }
    } = useForm();

    const handleClick = () => {
        setShow(!show);
    }

    const onSubmit = (values) => {
        dispatch(login(values, afterSuccess, afterError));
    }

    const afterSuccess = () => {
        navigate('/authentication');
    }

    const afterError = (error) => {
        setEmailError(error?.email);
        setPassError(error?.pass);
    }

    return (
        <Box bgColor={'#2BB673'} w={'100%'} h={'100vh'}>
            <Helmet>
                <title>Login | Remitless</title>
            </Helmet>
            <Box>
                <SimpleGrid columns={2} h={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'space-around'}>
                    <Box>
                        <Image src={LogoWhite} />
                    </Box>
                    <Box pos={'relative'} zIndex={'9'}>
                        <Box
                            sx={{
                                _before: {
                                    content: '""',
                                    pos: 'absolute',
                                    top: '0',
                                    left: '-35px',
                                    h: '90%',
                                    w: '100%',
                                    bgColor: '#ffffff60',
                                    borderRadius: '30px 0px 0px 30px',
                                    m: '30px 0px 0px 0px',
                                    zIndex: -1
                                }
                            }}
                        ></Box>
                        <Box
                            sx={{
                                _before: {
                                    content: '""',
                                    pos: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    left: '-70px',
                                    h: '80%',
                                    w: '100%',
                                    bgColor: '#ffffff40',
                                    borderRadius: '30px 0px 0px 30px',
                                    m: '60px 0px 0px 0px',
                                    zIndex: -1
                                }
                            }}
                        ></Box>
                        <Box
                            sx={{
                                _before: {
                                    content: '""',
                                    pos: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    left: '-105px',
                                    h: '70%',
                                    w: '100%',
                                    bgColor: '#ffffff20',
                                    borderRadius: '30px 0px 0px 30px',
                                    m: '90px 0px 0px 0px',
                                    zIndex: -1
                                }
                            }}
                        ></Box>
                        <Box bgColor={'#fff'} w={'600px'} h={'650px'} borderRadius={'30px'}>
                            <Flex p={'50px 75px 50px 75px'} flexDirection={'column'} alignItems={'center'}>
                                <Text color={'#000'} fontSize={'20px'} fontFamily={'poppins'} mb={'10px'}>Welcome to!</Text>
                                <Image src={Logo} w={'250px'} />
                                <Box w={'100%'} mt={'60px'}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <FormControl mb={'30px'} isInvalid={errors?.email?.message || emailError}>
                                            <FormLabel color={'#000'}>Email address*</FormLabel>
                                            <Input
                                                bgColor={'#F8F7FC'}
                                                borderColor={'#F8F7FC'}
                                                _hover={{ borderColor: '#F8F7FC' }}
                                                color={'#9A999C'}
                                                {...register("email", {
                                                    required: "This is required *",
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: "Invalid email format",
                                                    },
                                                })}
                                            />
                                            {errors?.email?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.email?.message}</Text>}
                                            {emailError && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{emailError}</Text>}
                                        </FormControl>
                                        <FormControl isInvalid={errors?.password?.message || passError}>
                                            <Flex justifyContent={'space-between'}>
                                                <FormLabel color={'#000'}>Password*</FormLabel>
                                                <Link to={'/forgot-password'} style={{ textDecoration: 'underline', color: '#2BB673' }}>Forgot password</Link>
                                            </Flex>
                                            <InputGroup mb={'10px'}>
                                                <Input
                                                    type={show ? 'text' : 'password'}
                                                    bgColor={'#F8F7FC'}
                                                    borderColor={'#F8F7FC'}
                                                    _hover={{ borderColor: '#F8F7FC' }}
                                                    color={'#9A999C'}
                                                    {...register('password', {
                                                        required: 'This is required *',
                                                        minLength: { value: 8, message: "Password must have at least 8 characters" }
                                                    })}
                                                />
                                                <InputRightElement>
                                                    {show ?
                                                        <ViewOffIcon onClick={handleClick} cursor={'pointer'} color={'#9A999C'} /> :
                                                        <ViewIcon onClick={handleClick} cursor={'pointer'} color={'#9A999C'} />
                                                    }
                                                </InputRightElement>
                                            </InputGroup>
                                            {errors?.password?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.password?.message}</Text>}
                                            {passError && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{passError}</Text>}
                                        </FormControl>
                                        <FormControl mb={'40px'}>
                                            <Checkbox color={'#B1ADC0'}>Keep me signed in</Checkbox>
                                        </FormControl>
                                        <Button type='submit' isLoading={isSubmitting} bgColor={'#0A2B1B'} _hover={{ bgColor: '#2BB673' }} w={'100%'} color={'#fff'} fontWeight={300} textTransform={'uppercase'} p={'20px'} borderRadius={'6px'}>Login</Button>
                                    </form>
                                    <HStack gap={'10px'} fontSize={'16px'} mt={'20px'}>
                                        <Text color={'#000'} fontWeight={'600'}>Not a member ?</Text>
                                        <Link to={'/sign-up'} style={{ textDecoration: 'underline', color: '#2BB673' }}>Sign up</Link>
                                    </HStack>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                </SimpleGrid>
            </Box>
        </Box>
    );
}

export default Login;
