export const IS_USER_REGISTRATION_LOADING = "IS_USER_REGISTRATION_LOADING";
export const IS_USER_REGISTRATION_SUCCESS = "IS_USER_REGISTRATION_SUCCESS";
export const IS_USER_REGISTRATION_FAILED = "IS_USER_REGISTRATION_FAILED";

export const IS_USER_LOGIN_LOADING = "IS_USER_LOGIN_LOADING";
export const IS_USER_LOGIN_SUCCESS = "IS_USER_LOGIN_SUCCESS";
export const IS_USER_LOGIN_FAILED = "IS_USER_LOGIN_FAILED";

export const IS_VERIFY_OTP_LOADING = "IS_VERIFY_OTP_LOADING";
export const IS_VERIFY_OTP_SUCCESS = "IS_VERIFY_OTP_SUCCESS";
export const IS_VERIFY_OTP_FAILED = "IS_VERIFY_OTP_FAILED";

export const IS_USER_SIGNOUT = "IS_USER_SIGNOUT";