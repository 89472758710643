import { Box, Button, Flex, FormControl, FormLabel, HStack, Image, Input, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import Logo from '../../assets/images/logo.png';
import LogoWhite from '../../assets/images/logo-white.png';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { verifyOtp } from '../../redux/user/userActions';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Authentication = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state?.user);
    const isSubmitting = useSelector((state) => state?.user?.is_verify_otp_loading);
    const [otpError, setOtpError] = useState(null);

    const {
        handleSubmit,
        register,
        formState: { errors }
    } = useForm({
        defaultValues: {
            otp: user?.authenticationData?.user?.otp
        }
    });

    const onSubmit = (values) => {
        if (values?.otp === user?.authenticationData?.user?.otp) {
            setOtpError(null);
            const token = user?.authenticationData?.token;
            const data = {
                otp: values?.otp,
                userId: user?.authenticationData?.user?.id
            };
            dispatch(verifyOtp(data, token, afterSuccess, afterError));

        } else {
            setOtpError('OTP is invalid');
        }
    }

    const afterSuccess = () => {
        navigate('/dashboard');
    }

    const afterError = () => {
        setOtpError('OTP is invalid');
    }

    return (
        <Box bgColor={'#2BB673'} w={'100%'} h={'100vh'}>
            <Helmet>
                <title>Authenticate | Remitless</title>
            </Helmet>
            <Box>
                <SimpleGrid columns={2} h={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'space-around'}>
                    <Box>
                        <Image src={LogoWhite} />
                    </Box>
                    <Box pos={'relative'} zIndex={'9'}>
                        <Box
                            sx={{
                                _before: {
                                    content: '""',
                                    pos: 'absolute',
                                    top: '0',
                                    left: '-35px',
                                    h: '90%',
                                    w: '100%',
                                    bgColor: '#ffffff60',
                                    borderRadius: '30px 0px 0px 30px',
                                    m: '30px 0px 0px 0px',
                                    zIndex: -1
                                }
                            }}
                        ></Box>
                        <Box
                            sx={{
                                _before: {
                                    content: '""',
                                    pos: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    left: '-70px',
                                    h: '80%',
                                    w: '100%',
                                    bgColor: '#ffffff40',
                                    borderRadius: '30px 0px 0px 30px',
                                    m: '60px 0px 0px 0px',
                                    zIndex: -1
                                }
                            }}
                        ></Box>
                        <Box
                            sx={{
                                _before: {
                                    content: '""',
                                    pos: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    left: '-105px',
                                    h: '70%',
                                    w: '100%',
                                    bgColor: '#ffffff20',
                                    borderRadius: '30px 0px 0px 30px',
                                    m: '90px 0px 0px 0px',
                                    zIndex: -1
                                }
                            }}
                        ></Box>
                        <Box bgColor={'#fff'} w={'600px'} h={'650px'} borderRadius={'30px'}>
                            <Flex p={'50px 75px 50px 75px'} flexDirection={'column'} alignItems={'center'}>
                                <Image src={Logo} w={'250px'} />
                                <Text color={'#000'} fontSize={'20px'} fontFamily={'poppins'} mt={'40px'}>Please Verify</Text>
                                <Box w={'100%'} mt={'40px'}>
                                    <Text color={'#000'} mb={'30px'} fontWeight={'600'}>Enter the OTP that we send on {user?.authenticationData?.user?.phone} to continue.</Text>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <FormControl mb={'30px'} isInvalid={errors?.otp?.message || otpError}>
                                            <FormLabel color={'#000'}>OTP*</FormLabel>
                                            <Input
                                                bgColor={'#F8F7FC'}
                                                borderColor={'#F8F7FC'}
                                                fontSize={'20px'}
                                                fontWeight={'600'}
                                                _hover={{ borderColor: '#F8F7FC' }}
                                                color={'#9A999C'}
                                                {...register("otp", {
                                                    required: "This is required *",
                                                    minLength: { value: 6, message: "Minimum length should be 6" },
                                                    maxLength: { value: 6, message: "Maximum length should be 6" }
                                                })}
                                            />
                                            {errors?.otp?.message && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{errors?.otp?.message}</Text>}
                                            {otpError && <Text color={'#f00'} fontSize={'13px'} mt={'3px'}>{otpError}</Text>}
                                        </FormControl>
                                        <Button type='submit' isLoading={isSubmitting} bgColor={'#0A2B1B'} _hover={{ bgColor: '#2BB673' }} w={'100%'} color={'#fff'} fontWeight={300} textTransform={'uppercase'} p={'20px'} borderRadius={'6px'}>Submit</Button>
                                    </form>
                                    <HStack gap={'10px'} fontSize={'16px'} mt={'20px'}>
                                        <Text color={'#000'} fontWeight={'600'}>Didn't receive any text ?</Text>
                                        <Text textDecoration={'underline'} color={'#2BB673'} cursor={'pointer'} _hover={{ color: '#0a2b1b' }}>Resend</Text>
                                    </HStack>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                </SimpleGrid>
            </Box>
        </Box>
    );
}

export default Authentication;
