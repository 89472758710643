import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Routes from './Routes';
import reportWebVitals from './reportWebVitals';
import { border, ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = extendTheme({
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          bg: '#EEECF3',
          borderColor: '#EEECF3',
          _checked: {
            bg: '#2BB673',
            borderColor: '#2BB673',
            color: 'white',
            _hover: {
              bg: '#2BB673',
              borderColor: '#2BB673'
            }
          },
        },
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Routes />
          </PersistGate>
        </Provider>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
