import { Box, Button, Checkbox, Flex, FormControl, FormLabel, HStack, Image, Input, InputGroup, InputRightElement, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import Logo from '../../assets/images/logo.png';
import LogoWhite from '../../assets/images/logo-white.png';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ForgetPassword = () => {
    return (
        <Box bgColor={'#2BB673'} w={'100%'} h={'100vh'}>
            <Helmet>
                <title>Forgot Password | Remitless</title>
            </Helmet>
            <Box>
                <SimpleGrid columns={2} h={'100vh'} display={'flex'} alignItems={'center'} justifyContent={'space-around'}>
                    <Box>
                        <Image src={LogoWhite} />
                    </Box>
                    <Box pos={'relative'} zIndex={'9'}>
                        <Box
                            sx={{
                                _before: {
                                    content: '""',
                                    pos: 'absolute',
                                    top: '0',
                                    left: '-35px',
                                    h: '90%',
                                    w: '100%',
                                    bgColor: '#ffffff60',
                                    borderRadius: '30px 0px 0px 30px',
                                    m: '30px 0px 0px 0px',
                                    zIndex: -1
                                }
                            }}
                        ></Box>
                        <Box
                            sx={{
                                _before: {
                                    content: '""',
                                    pos: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    left: '-70px',
                                    h: '80%',
                                    w: '100%',
                                    bgColor: '#ffffff40',
                                    borderRadius: '30px 0px 0px 30px',
                                    m: '60px 0px 0px 0px',
                                    zIndex: -1
                                }
                            }}
                        ></Box>
                        <Box
                            sx={{
                                _before: {
                                    content: '""',
                                    pos: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    left: '-105px',
                                    h: '70%',
                                    w: '100%',
                                    bgColor: '#ffffff20',
                                    borderRadius: '30px 0px 0px 30px',
                                    m: '90px 0px 0px 0px',
                                    zIndex: -1
                                }
                            }}
                        ></Box>
                        <Box bgColor={'#fff'} w={'600px'} h={'650px'} borderRadius={'30px'}>
                            <Flex p={'50px 75px 50px 75px'} flexDirection={'column'} alignItems={'center'}>
                                <Image src={Logo} w={'250px'} />
                                <Text color={'#000'} fontSize={'20px'} fontFamily={'poppins'} mt={'40px'}>Forgot Password</Text>
                                <Box w={'100%'} mt={'40px'}>
                                    <Text color={'#000'} mb={'30px'} fontWeight={'600'}>Enter your email address we will send you an email with reset password link.</Text>
                                    <form>
                                        <FormControl mb={'30px'}>
                                            <FormLabel color={'#000'}>Email address*</FormLabel>
                                            <Input bgColor={'#F8F7FC'} borderColor={'#F8F7FC'} _hover={{ borderColor: '#F8F7FC' }} color={'#9A999C'} />
                                        </FormControl>
                                        <Button type='submit' bgColor={'#0A2B1B'} _hover={{ bgColor: '#2BB673' }} w={'100%'} color={'#fff'} fontWeight={300} textTransform={'uppercase'} p={'20px'} borderRadius={'6px'}>Send</Button>
                                    </form>
                                    <HStack gap={'10px'} fontSize={'16px'} mt={'20px'}>
                                        <Text color={'#000'} fontWeight={'600'}>Remember you password ?</Text>
                                        <Link to={'/login'} style={{ textDecoration: 'underline', color: '#2BB673' }}>Log In</Link>
                                    </HStack>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                </SimpleGrid>
            </Box>
        </Box>
    );
}

export default ForgetPassword;
