import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import ForgetPassword from './pages/auth/ForgetPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Authentication from './pages/auth/Authentication';
import Dashboard from './pages/Dashboard';
import GuestRoute from './routes/GuestRoute';
import ProtectedRoute from './routes/ProtectedRoute';

function App() {
  return (
    <Routes>
      <Route path="*" element={<ProtectedRoute element={<Dashboard />} />} />
      <Route path="/login" element={<GuestRoute element={<Login />} />} />
      <Route path="/sign-up" element={<GuestRoute element={<Signup />} />} />
      <Route path="/forgot-password" element={<GuestRoute element={<ForgetPassword />} />} />
      <Route path="/reset-password" element={<GuestRoute element={<ResetPassword />} />} />
      <Route path="/authentication" element={<GuestRoute element={<Authentication />} />} />
      <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
    </Routes>
  );
}

export default App;
