import * as actionTypes from './userTypes';

const initialState = {
    user: [],
    authenticationData: [],
    is_user_registration_loading: false,
    is_user_login_loading: false,
    is_verify_otp_loading: false
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.IS_USER_REGISTRATION_LOADING:
            return {
                ...state,
                is_user_registration_loading: true
            };
        case actionTypes.IS_USER_REGISTRATION_SUCCESS:
            return {
                ...state,
                is_user_registration_loading: false,
                authenticationData: action.payload
            };
        case actionTypes.IS_USER_REGISTRATION_FAILED:
            return {
                ...state,
                is_user_registration_loading: false,
            };
        case actionTypes.IS_USER_LOGIN_LOADING:
            return {
                ...state,
                is_user_login_loading: true
            };
        case actionTypes.IS_USER_LOGIN_SUCCESS:
            return {
                ...state,
                is_user_login_loading: false,
                authenticationData: action.payload
            };
        case actionTypes.IS_USER_LOGIN_FAILED:
            return {
                ...state,
                is_user_login_loading: false,
            };
        case actionTypes.IS_VERIFY_OTP_LOADING:
            return {
                ...state,
                is_verify_otp_loading: true
            };
        case actionTypes.IS_VERIFY_OTP_SUCCESS:
            return {
                ...state,
                is_verify_otp_loading: false,
                user: action.payload,
                authenticationData: []
            };
        case actionTypes.IS_VERIFY_OTP_FAILED:
            return {
                ...state,
                is_verify_otp_loading: false,
            };
        case actionTypes.IS_USER_SIGNOUT:
            return {
                ...state,
                user: [],
                authenticationData: []
            };
        default:
            return state;
    }
};

export default userReducer;
