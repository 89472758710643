import { Post } from '../../helpers/apicalls/apicalls';
import * as actionTypes from './userTypes';

export const signup = (data, afterSuccess, afterError) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_USER_REGISTRATION_LOADING });
        Post('/api/registration', data)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.IS_USER_REGISTRATION_SUCCESS,
                        payload: res?.data
                    });
                    afterSuccess();
                } else {
                    dispatch({ type: actionTypes.IS_USER_REGISTRATION_FAILED });
                    afterError(res?.message);
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.IS_USER_REGISTRATION_FAILED });
                console.log(err, "err");
            })
    }
}

export const login = (data, afterSuccess, afterError) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_USER_LOGIN_LOADING });
        Post('/api/login', data)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.IS_USER_LOGIN_SUCCESS,
                        payload: res?.data
                    });
                    afterSuccess();
                } else {
                    dispatch({ type: actionTypes.IS_USER_LOGIN_FAILED });
                    afterError(res?.error);
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.IS_USER_LOGIN_FAILED });
                console.log(err, "err");
            })
    }
}

export const verifyOtp = (data, token, afterSuccess, afterError) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_VERIFY_OTP_LOADING });
        Post('/api/verify-otp', data, token)
            .then((res) => {
                if (res?.status) {
                    dispatch({
                        type: actionTypes.IS_VERIFY_OTP_SUCCESS,
                        payload: { user: res?.data, token: token }
                    });
                    afterSuccess();
                } else {
                    dispatch({ type: actionTypes.IS_VERIFY_OTP_FAILED });
                    afterError();
                }
            })
            .catch((err) => {
                console.log(err, "err");
                dispatch({ type: actionTypes.IS_VERIFY_OTP_FAILED });
            })
    }
}

export const signOut = (afterSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_USER_SIGNOUT });
        afterSuccess();
    }
}