// GuestRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const GuestRoute = ({ element: Element, ...rest }) => {
    const isAuthenticated = useSelector((state) => state?.user?.user);

    return isAuthenticated.token ? <Navigate to="/dashboard" /> : Element;
};

export default GuestRoute;
